import { useQuery } from "@apollo/client";
import { LoadingOverlay } from "@heart/components";
import PropTypes from "prop-types";

import ApplicationProfiles from "@graphql/queries/ApplicationProfiles.graphql";

import EndUserUploadTypeTable from "./EndUserUploadTypeTable";

// TODO ENG-8390 Remove `recommendedDisplayDate` which relates to hacky BIAS stuff
const ApplicantUploadTypeTable = ({
  applicationId,
  applicationStageId,
  recommendedDisplayDate,
  applicantAgencyProfileId,
  coApplicantAgencyProfileId,
  previousCaregiverAgencyProfileIds,
}) => {
  const { loading, error, data } = useQuery(ApplicationProfiles, {
    variables: { id: applicationId },
  });

  if (error) {
    return false;
  }

  if (loading) {
    return <LoadingOverlay active={true} />;
  }

  const { application } = data;

  const sections = [
    {
      i18nRoleKey: "applicant",
      userAgencyProfileIds: [applicantAgencyProfileId],
    },
  ];

  if (coApplicantAgencyProfileId) {
    sections.push({
      i18nRoleKey: "co_applicant",
      userAgencyProfileIds: [coApplicantAgencyProfileId],
    });
  }

  if (previousCaregiverAgencyProfileIds?.length > 0) {
    sections.push({
      i18nRoleKey: "previous_caregivers",
      userAgencyProfileIds: previousCaregiverAgencyProfileIds,
    });
  }

  if (application.applicationChildrenInHome?.length > 0) {
    sections.push({
      i18nRoleKey: "children_in_home",
      userAgencyProfileIds: application.applicationChildrenInHome?.map(
        ({ userAgencyProfile: { id } }) => id
      ),
    });
  }
  sections.push({ i18nRoleKey: "household" });

  return (
    <EndUserUploadTypeTable
      {...{
        applicationId,
        applicationStageId,
        recommendedDisplayDate,
        sections,
      }}
    />
  );
};

ApplicantUploadTypeTable.propTypes = {
  applicationId: PropTypes.number.isRequired,
  applicationStageId: PropTypes.number,
  recommendedDisplayDate: PropTypes.string.isRequired,
  applicantAgencyProfileId: PropTypes.number.isRequired,
  coApplicantAgencyProfileId: PropTypes.number,
  previousCaregiverAgencyProfileIds: PropTypes.arrayOf(PropTypes.number),
};

export default ApplicantUploadTypeTable;
